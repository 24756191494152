// Editor configuration.

export const defaultConfig = {
    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList' ],
    heading: {
        options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Kop 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Kop 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Kop 3', class: 'ck-heading_heading3' },
        ]
    },
    link: {
        decorators: {
            addTargetToLinks: {
                mode: 'manual',
                label: 'Open in a new tab',
                attributes: {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                }
            }
        }
    },
    image: {
        // You need to configure the image toolbar, too, so it uses the new style buttons.
        toolbar: [ 'imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight' ],

        styles: [
            // This option is equal to a situation where no style is applied.
            'full',

            // This represents an image aligned to the left.
            'alignLeft',

            // This represents an image aligned to the right.
            'alignRight'
        ]
    }
};

export const minimalConfig = {

    toolbar: [ 'bold', 'italic', 'link', 'bulletedList', 'numberedList' ],
    heading: {
        options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' }
        ]
    },
    link: {
        decorators: {
            addTargetToLinks: {
                mode: 'manual',
                label: 'Open in a new tab',
                attributes: {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                }
            }
        }
    }
};