require('jquery');
require('jquery-ui-sortable-npm');
require('@popperjs/core');

require('./bootstrap');

require('perfect-scrollbar');
require('@coreui/coreui');
require( 'datatables.net-bs4' );

require( 'datatables.net-buttons-bs4' );
require('select2');

Cropper = require('cropperjs');

mask = require('jquery-mask-plugin/dist/jquery.mask.min');
moment = require('moment');
moment.locale = require('moment/locale/nl');

ClassicEditor = require( '@ckeditor/ckeditor5-build-classic' );
CKEditorConfigs = require('./ckeditor.configs');

require('chart.js');

$(document).ready(function() {

    // add select2
    $('.select2').select2({
        theme: 'bootstrap4',
    });
    if ($("#exams-id").length == 0 && $("#timecard-id").length == 0) {
        $(document.body).tooltip({
            html: true,
            selector: '[data-toggle="tooltip"]',
            delay: { "show": 100, "hide": 150 },
            title: function() {

                var that = this;

                // to prevent multiple ajax calls, we will use a cache
                if (that.tooltipTextCache) return that.tooltipTextCache;

                if (typeof $(this).data('tooltip') !== 'undefined') {

                    $.ajax({
                        url: '/admin/tooltip/' + encodeURI($(this).data('tooltip')),
                        type: 'get',
                        async: false,
                        success: function(response){
                            that.tooltipTextCache = response;
                        }
                    });
                }

                return that.tooltipTextCache;
            }
        });
    }

    // add plugins / events to datatable rows
    $.each(window.LaravelDataTables, function(index) {
        window.LaravelDataTables[index].on('draw.dt', function() {
            // setTimeout(function() {
            //     $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
            // }, 500);
        });
    });

    // show selected file name in custom file upload
    $(document).on('change', '.custom-file-input', function(){
        if (typeof this.files[0] !== 'undefined') {
            $(this).next('.custom-file-label').addClass("selected").html(this.files[0].name);
        }
    });

    /**
     * Language toggle
     *
     * Togglable fields must contain class "meta-field" and a class (e.g. .target-element) to which it will respond.
     * The toggle button should contain data-toggle="metaFields" and data-target=".target-element".
     */
    $(document).metaToggle('a[data-toggle="meta-field"]');

    // trigger the ckeditors
    setTimeout(function() {
        var allEditors = document.querySelectorAll('.ckeditor');
        for (var i = 0; i < allEditors.length; ++i) {
            ClassicEditor.create(allEditors[i], CKEditorConfigs[$(allEditors[i]).data('editor-config')] );
        }
    }, 0);
});


/**
 * Language toggle
 *
 * Togglable fields must contain class "meta-field" and a class (e.g. .target-element) to which it will respond.
 * The toggle button should contain data-toggle="metaFields" and data-target=".target-element".
 */
$.fn.metaToggle = function(el) {

    $('.meta-field:not(.active)').hide();

    this.on('click', el, function(e) {

        e.preventDefault();
        var $this = $(this);

        var toggleClass = '.' + $this.data('toggle');

        $(toggleClass).hide();

        $this.closest('.nav-tabs').find('.nav-link').removeClass('active');
        $this.addClass('active');

        $(toggleClass + $this.data('target')).show();
    });

    $(document).on('click', '.meta-field .flag-icon', function() {

        var languageCount = $('.language-tabs li').length;
        var activeLanguageIndex = $(this).closest('.meta-field').index() + 1;

        if (activeLanguageIndex < languageCount) {
            activeLanguageIndex++;
        } else {
            activeLanguageIndex=1;
        }

        $(el).closest('.nav').find('li:eq(' + (activeLanguageIndex-1) + ') > a').trigger('click');

    });
};
